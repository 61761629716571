import React from 'react';
import { ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import TextError from "./TextError";

function InputPhoneField(props) {
    const { label, name, touched, errors, formik, countryCode, ...rest } = props;

    const handleInputPhone = (status, value, countryData, number, isBlur) => {
        if (isBlur) {
            formik.setFieldTouched(name);
        } else {
            if (status) {
                formik.setFieldValue(name, number);
            } else {
                formik.setFieldValue(name, value);
            }
        }
    };


    return (
        <Form.Group>
            <IntlTelInput
                fieldId={name}
                fieldName={name}
                // containerClassName="intl-tel-input"
                inputClassName={`${touched[name] && errors[name] ? "error" : null}`}
                css={['intl-tel-input', 'form-control']}
                // utilsScript={'libphonenumber.js'}
                nationalMode={false}
                // autoHideDialCode={false}
                defaultCountry={countryCode.toLowerCase()}
                preferredCountries={[countryCode.toLowerCase()]}
                {...rest}
                value={formik.values.phone}
                onPhoneNumberChange={(status, value, countryData, number, id) => {
                    handleInputPhone(status, value, countryData, number, false);
                }}
                onPhoneNumberBlur={(status, value, countryData, number, id) => {
                    handleInputPhone(status, value, countryData, number, true);
                }}
                format
                formatOnInit={false}
            />
            <ErrorMessage name={name} component={TextError} />
        </Form.Group>
    )
}

export default InputPhoneField