import React, { Component } from 'react'
import SignLayout from '../layouts/SignLayout';

import SEO from '../components/SEO/SEO';
import DriverStageOne from '../containers/DriverStageOne'

let title = "Drive with GOJO to earn more"
let descriptions = "Drive with GOJO – Choose your hours, be your own boss and earn more with best commission rate"
// import TopStatic from '';
let scriptFaceBookPixel = `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function()
{n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1088234585047003');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=1088234585047003&ev=PageView&noscript=1"
/></noscript>
`

class Driver extends Component {

    render() {
        let {location} = this.props
        return (
            <SignLayout>
              <SEO title={title} description={descriptions} scriptFaceBookPixel={scriptFaceBookPixel}/>
              <DriverStageOne location={location}/>
            </SignLayout>
        )
    }
}

export default Driver;