import React from "react";


function TextError(props) {
    return (
        <div className="error-message">
            {props.children}
        </div>
    )
}

export default TextError