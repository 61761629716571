import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'

import TextError from "./TextError";

function InputPhoneField(props) {
    const { label, name, touched, errors, formik, countryCode, ...rest } = props;
    const handleInputPhone = (e) => {

        const phoneNumber = parsePhoneNumberFromString(e.target.value, countryCode);
        if(phoneNumber){
            // console.log("phoneNumber.number ", phoneNumber);
            // console.log("phoneNumber.isValid() ", phoneNumber.isValid());
            formik.setFieldValue(name, new AsYouType().input(phoneNumber.number))
        } else{
            formik.setFieldValue(name, new AsYouType().input(e.target.value))
        }
    };

    return (
        <Form.Group>
            <Field
                id={name}
                name={name}
                {...rest}
                className={touched[name] && errors[name] ? "error" : null}
                // onClick={() => formik.setFieldValue(name, `+${countryCode} `)}
                onChange={(e) => handleInputPhone(e)}
            />
            <ErrorMessage name={name} component={TextError} />
        </Form.Group>
    )
}

export default InputPhoneField