import React, { useEffect } from 'react';
import { Field, ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';
import TextError from "./TextError";


function SelectField(props) {
    const { label, name, values, options, touched, errors, formik, getRegistrationType, ...rest } = props;

    useEffect(() => {
        if (name === "registrationType") {
            formik.setFieldValue(name, '');
        };
    }, [options])

    const handleOnChange = (e) => {
        let value = e.target.value;
        formik.setFieldValue(name, value);
        if (name === 'preferredCity') {
            const services = getRegistrationType(value);
            if (services.length === 1) {
                formik.setFieldValue('registrationType', services[0].value);
            };
        };
    };


    return (
        <Form.Group>
            <Field
                as='select'
                id={name}
                name={name}
                {...rest}
                className={values[name] === '' ? `select-box-placehover${touched[name] && errors[name] ? " error" : ''}` : `select-box${touched[name] && errors[name] ? " error" : ''}`}
                onChange={(e) => handleOnChange(e)}
            >
                <option value="" disabled hidden>{label}</option>
                {
                    options.map(option => {
                        return (
                            <option key={option.value} value={option.value}>
                                {option.key}
                            </option>
                        )
                    })
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </Form.Group>
    )
}

export default SelectField