import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import HButton from '../../components/HButton/HButtonGradient';
import HImage from '../../components/HImage/HImageByPath';
import BtnAndroid from '../../components/HImage/BtnDowloadAndroid';
import BtnIos from '../../components/HImage/BtnDownloadIOS';
import BtnHuawei from '../../components/HImage/BtnDownloadHuawei';
import DirectionLanguage from '../../components/DirectionLanguage';
import link from '../../../config/link'
import "react-multi-carousel/lib/styles.css";
import './DriverStageOne.scss'
import "react-multi-carousel/lib/styles.css";
import CountryContext from '../../context/CountryContext'
import play from '../../../static/images/play.svg'
import cancel from '../../../static/images/cancel.svg'
import "../../../node_modules/video-react/dist/video-react.css";
import YouTube from 'react-youtube';
import { UrlMarketPlaces } from '../../constants/commonData'
import SignUpForm from './SignUpForm';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Media from 'react-media';
import '../Driver/bannerDriver.scss';
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

const URL_CC_API = process.env.CC_API;

const opts = {
  playerVars: {
    "picture-in-picture": 0,
    rel: 0,
    loop: 1,
    showinfo: 0

  },
};

export default injectIntl((props) => {
  const Context = useContext(CountryContext)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState({});

  const [isShowVideo, setIsShowVideo] = useState(false);
  const [videoElement, setVideoElement] = useState(null);
  const countryCode = Context.country || 'my'
  const fleetId = Context.fleetId || ''
  const playVideo = () => {
    setIsShowVideo(true)
    videoElement.target.playVideo();
  }

  const closeVideo = () => {
    if (videoElement) {
      setIsShowVideo(false)
      videoElement.target.pauseVideo();
    }
  }

  const onReady = event => {
    setVideoElement(event)
  };

  useEffect(() => {
    if (fleetId !== '') {
      fetch(URL_CC_API + '/driver-sign-up/getFleetInfo?fleetId=' + fleetId)
        .then(response => {
          if (props.location && props.location.hash == "#toSrcroll") {
            scrollTo('#provider_stage_one')
          }
          return response.json();
        })
        .then(json => {
          setData(json.res);
          setLoading(false);
        })
        .catch(error => {
          if (props.location && props.location.hash == "#toSrcroll") {
            scrollTo('#provider_stage_one')
          }
          setError(true);
        })
    }
  }, [fleetId]);

  let howDriverPath1 = `../../../${countryCode}/images/driver/how_driver_1.png`;
  let howDriverPath2 = `../../../${countryCode}/images/driver/how_driver_2.png`;
  let howDriverPath3 = `../../../${countryCode}/images/driver/how_driver_3.png`;
  let howDriverPath4 = `../../../${countryCode}/images/driver/how_driver_4.png`;
  if (props.intl.locale === 'ge-ka') {
    howDriverPath1 = `../../../${countryCode}/images/driver/how_driver_1_ka.png`;
    howDriverPath2 = `../../../${countryCode}/images/driver/how_driver_2_ka.png`;
    howDriverPath3 = `../../../${countryCode}/images/driver/how_driver_3_ka.png`;
    howDriverPath4 = `../../../${countryCode}/images/driver/how_driver_4_ka.png`;
  } else if (props.intl.locale === 'ge-ru') {
    howDriverPath1 = `../../../${countryCode}/images/driver/how_driver_1_ru.png`;
    howDriverPath2 = `../../../${countryCode}/images/driver/how_driver_2_ru.png`;
    howDriverPath3 = `../../../${countryCode}/images/driver/how_driver_3_ru.png`;
    howDriverPath4 = `../../../${countryCode}/images/driver/how_driver_4_ru.png`;
  }

  return (
    <>
      <section id="mBaner_driver">
        {
          countryCode !== 'ms'
            ? <div className="bannerImg">
              <img
                src={`../../../${countryCode}/images/driver/driver_banner.jpg`}
                className="bannerImage d-none d-sm-block"
              />
              <img
                src={`../../../${countryCode}/images/driver/driver_banner_mobile.jpg`}
                className="bannerImage d-block d-sm-none"
              />
            </div>
            : <>
              <div className="bannerImg">
                <img
                  onClick={playVideo}
                  src={`../../../${countryCode}/images/driver/driver_banner.jpg`}
                  className="bannerImage d-none d-sm-block"
                />
                <img
                  onClick={playVideo}
                  src={`../../../${countryCode}/images/driver/driver_banner_mobile.jpg`}
                  className="bannerImage d-block d-sm-none"
                />
              </div>
              <img src={play} className="play_btn" onClick={playVideo} />
            </>
        }
      </section>
      <section id="provider_stage_one">
        <div className="content-stage-one">
          <SignUpForm
            commonData={data}
            loading={loading}
            error={error}
          />
        </div>
      </section>
      <a href="#scroll"></a>
      <div className={isShowVideo ? "modalVideo modalVideo-open fadeModal showModal" : "modalVideo"}>
        <img src={cancel} onClick={closeVideo} className="close-video" />
        <div className="ytb-container">
          <div className="video_power">
            <YouTube
              videoId={"wts66jVUsk0"}
              id="videoBanner"
              className="video"
              onReady={onReady}
              onEnd={closeVideo}
              opts={opts}
            />
          </div>
        </div>
      </div>
      <section id="be_driver">
        <div className="h-container">
          <h2><FormattedMessage id="driver.beDriver.title" /></h2>
          <div className="be_driver_main">
            <div className="be_driver_content">
              <HImage filename="driver/be_driver_1.png" className="be_icon" />
              <h4>{props.intl.formatMessage({ id: "driver.beDriver.content_1.title" }, { br: <br /> })}</h4>
              <p>{props.intl.formatMessage({ id: "driver.beDriver.content_1.content" }, { br: <br /> })}</p>
            </div>
            <div className="be_driver_content">
              <HImage filename="driver/be_driver_2.png" className="be_icon" />
              <h4>{props.intl.formatMessage({ id: "driver.beDriver.content_2.title" }, { br: <br /> })}</h4>
              <p>{props.intl.formatMessage({ id: "driver.beDriver.content_2.content" }, { br: <br /> })}</p>
            </div>
            <div className="be_driver_content">
              <HImage filename="driver/be_driver_3.png" className="be_icon" />
              <h4>{props.intl.formatMessage({ id: "driver.beDriver.content_3.title" }, { br: <br /> })}</h4>
              <p>{props.intl.formatMessage({ id: "driver.beDriver.content_3.content" }, { br: <br /> })}</p>
            </div>
          </div>
        </div>
      </section>
      <section id="how_driver">
        <div className="h-container">
          <h2>{props.intl.formatMessage({ id: "driver.howWork.title" })}</h2>
          <div className="d-block d-sm-none">
            <Row>
              <Col xs="6" md="3">
                <div className="content_how">
                  <img src={howDriverPath1} className="be_icon" />
                  <DirectionLanguage>
                    <h4>{props.intl.formatMessage({ id: "driver.howWork.content_1.title" })}</h4>
                  </DirectionLanguage>
                  <p>{props.intl.formatMessage({ id: "driver.howWork.content_1.content" })}</p>
                </div>
              </Col>
              <Col xs="6" md="3">
                <div className="content_how">
                  <img src={howDriverPath2} className="be_icon" />
                  <DirectionLanguage>
                    <h4>{props.intl.formatMessage({ id: "driver.howWork.content_2.title" })}</h4>
                  </DirectionLanguage>
                  <p>{props.intl.formatMessage({ id: "driver.howWork.content_2.content" })}</p>
                </div>
              </Col>
              <Col xs="6" md="3">
                <div className="content_how">
                  <img src={howDriverPath3} className="be_icon" />
                  <DirectionLanguage>
                    <h4>{props.intl.formatMessage({ id: "driver.howWork.content_3.title" })}</h4>
                  </DirectionLanguage>
                  <p>{props.intl.formatMessage({ id: "driver.howWork.content_3.content" })}</p>
                </div>
              </Col>
              <Col xs="6" md="3">
                <div className="content_how">
                  <img src={howDriverPath4} className="be_icon" />
                  <DirectionLanguage>
                    <h4>{props.intl.formatMessage({ id: "driver.howWork.content_4.title" })}</h4>
                  </DirectionLanguage>
                  <p>{props.intl.formatMessage({ id: "driver.howWork.content_4.content" })}</p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="d-none d-sm-block">
            <Row>
              <Col xs="6" md="3">
                <div className="content_how">
                  <img src={howDriverPath1} className="be_icon" />
                </div>
              </Col>
              <Col xs="6" md="3">
                <div className="content_how">
                  <img src={howDriverPath2} className="be_icon" />
                </div>
              </Col>
              <Col xs="6" md="3">
                <div className="content_how">
                  <img src={howDriverPath3} className="be_icon" />
                </div>
              </Col>
              <Col xs="6" md="3">
                <div className="content_how">
                  <img src={howDriverPath4} className="be_icon" />
                </div>
              </Col>
              <Col xs="6" md="3">
                <div className="content_how">
                  <DirectionLanguage>
                    <h4>{props.intl.formatMessage({ id: "driver.howWork.content_1.title" })}</h4>
                  </DirectionLanguage>
                </div>
              </Col>
              <Col xs="6" md="3">
                <div className="content_how">
                  <DirectionLanguage>
                    <h4>{props.intl.formatMessage({ id: "driver.howWork.content_2.title" })}</h4>
                  </DirectionLanguage>
                </div>
              </Col>
              <Col xs="6" md="3">
                <div className="content_how">
                  <DirectionLanguage>
                    <h4>{props.intl.formatMessage({ id: "driver.howWork.content_3.title" })}</h4>
                  </DirectionLanguage>
                </div>
              </Col>
              <Col xs="6" md="3">
                <div className="content_how">
                  <DirectionLanguage>
                    <h4>{props.intl.formatMessage({ id: "driver.howWork.content_4.title" })}</h4>
                  </DirectionLanguage>
                </div>
              </Col>
              <Col xs="6" md="3">
                <div className="content_how">
                  <p>{props.intl.formatMessage({ id: "driver.howWork.content_1.content" })}</p>
                </div>
              </Col>
              <Col xs="6" md="3">
                <div className="content_how">
                  <p>{props.intl.formatMessage({ id: "driver.howWork.content_2.content" })}</p>
                </div>
              </Col>
              <Col xs="6" md="3">
                <div className="content_how">
                  <p>{props.intl.formatMessage({ id: "driver.howWork.content_3.content" })}</p>
                </div>
              </Col>
              <Col xs="6" md="3">
                <div className="content_how">
                  <p>{props.intl.formatMessage({ id: "driver.howWork.content_4.content" })}</p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dowload_how_driver">
            <>
              <Media query="(max-width: 1023px)" render={() =>
              (
                <HButton
                  htmlType="link"
                  href={'#provider_stage_one'}
                  keyWord={props.intl.formatMessage({ id: "button.becomeDriver" })}
                  className="hummingbird-header__signUpBtn"
                />
              )}
              />
              <Media query="(min-width: 1024px)" render={() =>
              (
                <HButton
                  htmlType="link"
                  href={'#mBaner_driver'}
                  keyWord={props.intl.formatMessage({ id: "button.becomeDriver" })}
                  className="hummingbird-header__signUpBtn"
                />
              )}
              />
            </>
            <div className="bt_store">
              <a href={countryCode !== 'jo' ? link.driverGooglePlay : link.driverAppStoreJordan} target="_blank">
                <BtnAndroid intl={props.intl} />
              </a>
              <a href={countryCode !== 'jo' ? link.driverAppStore : link.driverAppStoreJordan} target="_blank">
                <BtnIos intl={props.intl} />
              </a>
              {
                data?.fleet?.socialSetting?.appGalleryDrv &&
                <a href={data?.fleet?.socialSetting?.appGalleryDrv} target="_blank">
                  <BtnHuawei intl={props.intl} />
                </a>
              }
            </div>
          </div>
        </div>
      </section>
      <section id="power_driver">
        <div className="h-container">
          <h2>{props.intl.formatMessage({ id: "driver.givingPower.title" })}</h2>
          <Row>
            <Col xs="12" md="7">
              <div className="video_power">
                <iframe
                  src={`${UrlMarketPlaces[countryCode]}?&rel=0`}
                  frameBorder="0"
                  allow="accelerometer; rel; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="video"
                >
                </iframe>
              </div>
            </Col>
            <Col xs="12" md="5" className="col_power">
              <div className="power_content">
                <h4>{props.intl.formatMessage({ id: "driver.givingPower.title_sub" })}</h4>
                <p>{props.intl.formatMessage({ id: "driver.givingPower.content" })}</p>
                <>
                  <Media query="(max-width: 1023px)" render={() =>
                  (
                    <HButton
                      htmlType="link"
                      href={'#provider_stage_one'}
                      keyWord={props.intl.formatMessage({ id: "button.withDriver" })}
                      className="hummingbird-header__signUpBtn"
                    />
                  )}
                  />
                  <Media query="(min-width: 1024px)" render={() =>
                  (
                    <HButton
                      htmlType="link"
                      href={'#mBaner_driver'}
                      keyWord={props.intl.formatMessage({ id: "button.withDriver" })}
                      className="hummingbird-header__signUpBtn"
                    />
                  )}
                  />
                </>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section id="safety_driver">
        <div className="h-container">
          <h2>{props.intl.formatMessage({ id: "driver.safety.title" })}</h2>
          <Row>
            <Col xs="6" md="6">
              <div className="safety_content">
                <div className="icon_safety">
                  <HImage filename="driver/safety_1.png" className="be_icon" />
                </div>
                <div>
                  <h4>{props.intl.formatMessage({ id: "driver.safety.content_1.title" })}</h4>
                  <p>{props.intl.formatMessage({ id: "driver.safety.content_1.content" })}</p>
                </div>
              </div>
            </Col>
            <Col xs="6" md="6">
              <div className="safety_content">
                <div className="icon_safety">
                  <HImage filename="driver/safety_2.png" className="be_icon" />
                </div>
                <div>
                  <h4>{props.intl.formatMessage({ id: "driver.safety.content_2.title" })}</h4>
                  <p>{props.intl.formatMessage({ id: "driver.safety.content_2.content" })}</p>
                </div>
              </div>
            </Col>
            <Col xs="6" md="6">
              <div className="safety_content">
                <div className="icon_safety">
                  <HImage filename="driver/safety_3.png" className="be_icon" />
                </div>
                <div>
                  <h4>{props.intl.formatMessage({ id: "driver.safety.content_3.title" })}</h4>
                  <p>{props.intl.formatMessage({ id: "driver.safety.content_3.content" })}</p>
                </div>
              </div>
            </Col>
            <Col xs="6" md="6">
              <div className="safety_content">
                <div className="icon_safety">
                  <HImage filename="driver/safety_4.png" className="be_icon" />
                </div>
                <div>
                  <h4>{props.intl.formatMessage({ id: "driver.safety.content_4.title" })}</h4>
                  <p>{props.intl.formatMessage({ id: "driver.safety.content_4.content" })}</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
})