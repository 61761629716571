import React from 'react';
import InputField from "./InputField";
import InputFieldName from './InputFieldName';
import InputPhoneField from './InputPhoneField';
import InputPhoneIntlTelField from './InputPhoneIntlTelField';
import SelectField from './SelectField';
import Policy from './Policy';

function FormikControl(props) {
    const { control, ...rest } = props;
    switch (control) {
        case 'input':
            return <InputField {...rest} />
        case 'inputName':
            return <InputFieldName {...rest} />
        case 'inputPhone':
            return <InputPhoneField {...rest} />
        case 'inputPhoneIntlTel':
            return <InputPhoneIntlTelField {...rest} />
        case 'select':
            return <SelectField {...rest} />
        case 'policy':
            return <Policy {...rest} />
        default: return null
    }
}

export default FormikControl