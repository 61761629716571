import React, { useEffect, useState, useRef, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Row, Col, FormGroup, Fade, Spinner } from 'react-bootstrap';
import _ from 'lodash';
import axios from 'axios';
import queryString from "query-string";

import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { injectIntl } from "gatsby-plugin-intl"
import ReCAPTCHA from 'react-google-recaptcha';
import CountryContext from "../../context/CountryContext";
import ThankYou from './ThankYou';
import FormikControl from '../../components/Formik/FormikControl';
import AppConfig from '../../../config/App.config';
import AllCountries from './AllCountries';

const RECAPTCHA = process.env.RECAPTCHA
const URL_CC_API = process.env.CC_API

const SignUpForm = ({ intl, ...props }) => {
    const Context = useContext(CountryContext)
    const [submited, setSubmited] = useState(false);
    const [fleetInfo, setFleetInfo] = useState({});
    const [cities, setCities] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [drvSignUpLanguages, setDrvSignUpLanguages] = useState([]);
    const [token, setToken] = useState('');
    const [keyRecapcha, setKeyRecapcha] = useState(0);
    const [loading, setLoading] = useState(false);
    const [resData, setResData] = useState({});
    const [registrationType, setRegistrationType] = useState([]);
    const [groupBrand, setGroupBrand] = useState({ enable: false });
    const [capitalLetter, setCapitalLetter] = useState(false);
    const [usePassword, setUsePassword] = useState(false);
    const reCaptcha = useRef();
    const parsedQuery = typeof window !== 'undefined' && queryString.parse(window.location.search);

    let mainColor = fleetInfo && fleetInfo.providerSetting && fleetInfo.providerSetting.otherSetting ? fleetInfo.providerSetting.otherSetting.mainColor : '#B38806'

    useEffect(() => {
        if (props.commonData && props.commonData.fleet) {
            setFleetInfo(props.commonData.fleet);
            if (props.commonData.fleet.capitalLetter) {
                const isCapitalLetter = props.commonData.fleet.capitalLetter.name.drvSignUp ? props.commonData.fleet.capitalLetter.name.drvSignUp : false;
                setCapitalLetter(isCapitalLetter);
                const syncPaxProfile = props.commonData.fleet.syncPaxProfile && props.commonData.fleet.syncPaxProfile;
                setGroupBrand(syncPaxProfile);
            };
            if (props.commonData.fleet.password && props.commonData.fleet.password.driver) {
                setUsePassword(true);
            };
        }
        if (props.commonData && props.commonData.city) {
            setCities(props.commonData.city);
        };
        if (props.commonData && props.commonData.company && props.commonData.company.list) {
            setCompanies(props.commonData.company.list);
        };
        if (props.commonData && props.commonData.drvSignUpLanguages) {
            setDrvSignUpLanguages(props.commonData.drvSignUpLanguages);
        };
        // getRegistrationType();
    }, [props.commonData])

    useEffect(() => {
        setKeyRecapcha(keyRecapcha + 1);
    }, [intl.locale])

    const getCity = () => {
        let key;
        let value;
        let citys = [];
        _.forEach(cities, e => {
            if (e.isShowDriverSignUp) {
                if (e.transportation || e.assistant || e.delivery) {
                    key = e.name;
                    value = e._id;
                    citys.push({ key, value });
                };
            };
        });
        return citys;
    };

    const getRegistrationType = (cityId) => {
        const cityInfo = _.filter(cities, { _id: cityId })[0];
        let services = [];
        if (fleetInfo) {
            const { providerSetting } = fleetInfo;
            if (providerSetting) {
                if (cityInfo.transportation) {
                    if (providerSetting.driverIndividual.enable) {
                        services.push({ key: intl.formatMessage({ id: "driver.formSignUp.INDIVIDUAL_CAR" }), value: '0' })
                    };
                    if (providerSetting.driverCompany.enable) {
                        services.push({ key: intl.formatMessage({ id: "driver.formSignUp.COMPANY_CAR" }), value: '1' })
                    };
                };
                if (cityInfo.delivery) {
                    if (providerSetting.deliveryDrvIndividual && providerSetting.deliveryDrvIndividual.enable) {
                        services.push({ key: intl.formatMessage({ id: "driver.formSignUp.DELIVERY_INDIVIDUAL_CAR" }), value: '2' })
                    };
                    if (providerSetting.deliveryDrvCompany && providerSetting.deliveryDrvCompany.enable) {
                        services.push({ key: intl.formatMessage({ id: "driver.formSignUp.DELIVERY_COMPANY_CAR" }), value: '3' })
                    };
                };
            };
        };
        setRegistrationType(services);
        return services;
    };

    const getLinkterm = (fleetInfo && fleetInfo.providerSetting) ? fleetInfo.providerSetting.otherSetting.termsOfUse : ''
    const getLinkPrivacyPolicy = (fleetInfo && fleetInfo.providerSetting) ? fleetInfo.providerSetting.otherSetting.privacyPolicy : ''
    const countryCode = fleetInfo && fleetInfo.countryCode ? fleetInfo.countryCode.toUpperCase() : '';

    const getCountryCode = () => {
        let data = '';
        const countries = AllCountries.getCountries();
        data = _.filter(countries, country => {
            return country.iso2 === countryCode.toLowerCase()
        })
        return data;
    };
    const phoneInit = getCountryCode()[0] ? `+${getCountryCode()[0].dialCode}` : '';


    const initialValues = {
        preferredCity: '',
        service: 'transport',
        registrationType: registrationType.length && registrationType.length <= 1 ? registrationType[0].value : '',
        firstName: '',
        lastName: '',
        email: '',
        phone: phoneInit,
        referralCode: parsedQuery && parsedQuery.referralID ? parsedQuery.referralID : '',
        OSInfo: 'Driver signup (Web)'
    };

    const phoneRegExp = /^[\+\d]?(?:[\d-.\s()]*)$/;

    const validationSchema = Yup.object({
        preferredCity: Yup.string().required(intl.formatMessage({ id: "driver.formSignUp.requiredField" })),
        service: Yup.string().required(intl.formatMessage({ id: "driver.formSignUp.requiredField" })),
        registrationType: Yup.string().required(intl.formatMessage({ id: "driver.formSignUp.requiredField" })),
        firstName: Yup.string().required(intl.formatMessage({ id: "driver.formSignUp.requiredField" })),
        lastName: Yup.string().required(intl.formatMessage({ id: "driver.formSignUp.requiredField" })),
        email: Yup.string().email(intl.formatMessage({ id: "driver.formSignUp.invalidEmail" })).required(intl.formatMessage({ id: "driver.formSignUp.requiredField" })),
        phone: Yup.string().min(8, intl.formatMessage({ id: "driver.formSignUp.requiredField" })).matches(phoneRegExp, intl.formatMessage({ id: "driver.formSignUp.invalidPhone" })).required(intl.formatMessage({ id: "driver.formSignUp.requiredField" })),
        referralCode: Yup.string(),
        OSInfo: Yup.string(),
    });

    const validate = values => {
        let errors = {};
        if (values.phone) {
            const phoneNumber = parsePhoneNumberFromString(values.phone, countryCode);
            if (values.phone[0] !== '+') {
                errors.phone = intl.formatMessage({ id: "driver.formSignUp.invalidPhone" });
            };
            if (!phoneNumber) {
                errors.phone = intl.formatMessage({ id: "driver.formSignUp.invalidPhone" });
            };
            if (phoneNumber && !phoneNumber.isValid()) {
                errors.phone = intl.formatMessage({ id: "driver.formSignUp.invalidPhone" });
            };
        }
        return errors;
    }


    const onSubmit = (values, { setFieldError }) => {
        let params = JSON.parse(JSON.stringify(values));
        params = { ...params, token: token };
        let companyDefault = {};

        if (!token) {
            return;
        };
        if (groupBrand.enable) {
            params = {
                ...params,
                groupId: groupBrand.groupId,
                fleetId: fleetInfo.fleetId
            };
        };
        if (params.registrationType === '0' || params.registrationType === '2') {
            companyDefault = companies.find(e => e.name.toLowerCase() === 'gojo');
        };
        if (params.registrationType === '1' || params.registrationType === '3') {
            companyDefault = companies.find(e => e.name.toLowerCase() === 'matta');
        };
        if (companyDefault && companyDefault._id) {
            params = {
                ...params,
                companyId: companyDefault._id
            };
        };
        setLoading(true);

        axios.post(URL_CC_API + "/driver-sign-up/create", params)
            .then(function (response) {
                reCaptcha.current.reset();
                setToken('');
                setLoading(false);

                if (response.status === 200) {
                    if (response.data.res.errorCode) {
                        switch (response.data.res.errorCode) {
                            case 3034:
                                // console.log("Error Code: ", response.data.res);
                                setFieldError('phone', intl.formatMessage({ id: "driver.formSignUp.PHONE_NUNBER_EXITS" }));
                                // phoneAlreadyInUse.push(response.data.res.errorCode);
                                break;
                            case 3037:
                                // console.log("Error Code: ", response.data.res);
                                break;
                            case 3039:
                                setFieldError('referralCode', intl.formatMessage({ id: "driver.formSignUp.REFERRAL_CODE_INVALID" }));
                                break;
                            case 3040:
                                setFieldError('phone', intl.formatMessage({ id: "driver.formSignUp.PHONE_NUNBER_EXITS_IN_GROUP" }));
                                break;
                            default:
                                break;
                        };
                    } else {
                        setResData(response.data.res);
                        setSubmited(true);
                    }
                }
            })
            .catch((err) => {
                console.log("err ", err)
            })
    };

    const loadingSpiner = () => {
        return (
            <div className="spinner-loading">
                <Spinner animation="grow" />
                {' '}
                <Spinner animation="grow" />
                {' '}
                <Spinner animation="grow" />
            </div>
        )
    };

    let fleetName = !props.loading && fleetInfo && fleetInfo.name;

    return (
        !fleetName ? (
            null
        ) : (
                !submited ?
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        validate={validate}
                    >{
                            formik => {
                                // console.log("formik.isValid ", formik.isValid);
                                // console.log("formik.values ", formik.values);
                                return (
                                    <Form className="form-action">
                                        <div className='box'>
                                            <div className='box-form'>
                                                <div className="fleetname">
                                                    <p
                                                        style={{ "color": `${mainColor}` }}
                                                        className="fleetname-content"
                                                    >
                                                        {intl.formatMessage({ id: "driver.formSignUp.title" }, { name: fleetInfo.name })}
                                                    </p>
                                                    <p>{intl.formatMessage({ id: "driver.formSignUp.label" })}</p>
                                                </div>

                                                <FormikControl
                                                    control='select'
                                                    label={intl.formatMessage({ id: "driver.formSignUp.city" })}
                                                    name='preferredCity'
                                                    values={formik.values}
                                                    options={getCity()}
                                                    touched={formik.touched}
                                                    errors={formik.errors}
                                                    formik={formik}
                                                    getRegistrationType={getRegistrationType}
                                                />
                                                {formik.values.service === "transport" ?
                                                    (registrationType.length <= 1 ?
                                                        null
                                                        :
                                                        <FormikControl
                                                            control='select'
                                                            label={intl.formatMessage({ id: "driver.formSignUp.selectDriver" })}
                                                            name='registrationType'
                                                            values={formik.values}
                                                            options={registrationType}
                                                            touched={formik.touched}
                                                            errors={formik.errors}
                                                            formik={formik}
                                                        />
                                                    )
                                                    : null}
                                                <Row>
                                                    <Col className="left-col">
                                                        <FormikControl
                                                            control='inputName'
                                                            type='text'
                                                            label={intl.formatMessage({ id: "driver.formSignUp.firstName" })}
                                                            name='firstName'
                                                            id='myTextField'
                                                            placeholder={intl.formatMessage({ id: "driver.formSignUp.firstName" })}
                                                            touched={formik.touched}
                                                            errors={formik.errors}
                                                            formik={formik}
                                                            capitalLetter={capitalLetter}
                                                        />
                                                    </Col>
                                                    <Col className="right-col">
                                                        <FormikControl
                                                            control='inputName'
                                                            type='text'
                                                            label={intl.formatMessage({ id: "driver.formSignUp.lastName" })}
                                                            name='lastName'
                                                            placeholder={intl.formatMessage({ id: "driver.formSignUp.lastName" })}
                                                            touched={formik.touched}
                                                            errors={formik.errors}
                                                            formik={formik}
                                                            capitalLetter={capitalLetter}
                                                        />
                                                    </Col>
                                                </Row>
                                                {/* <FormikControl
                                                    control='inputPhone'
                                                    type='text'
                                                    label='Mobile Number'
                                                    name='phone'
                                                    placeholder={phoneInit}
                                                    touched={formik.touched}
                                                    errors={formik.errors}
                                                    formik={formik}
                                                    countryCode={countryCode}
                                                /> */}
                                                <FormikControl
                                                    control='inputPhoneIntlTel'
                                                    type='text'
                                                    label='Mobile Number'
                                                    name='phone'
                                                    placeholder={phoneInit}
                                                    touched={formik.touched}
                                                    errors={formik.errors}
                                                    formik={formik}
                                                    countryCode={countryCode}
                                                />
                                                <FormikControl
                                                    control='input'
                                                    type='email'
                                                    label={intl.formatMessage({ id: "driver.formSignUp.email" })}
                                                    name='email'
                                                    placeholder={intl.formatMessage({ id: "driver.formSignUp.email" })}
                                                    touched={formik.touched}
                                                    errors={formik.errors}
                                                />
                                                <FormikControl
                                                    control='input'
                                                    type='referralCode'
                                                    label={intl.formatMessage({ id: "driver.formSignUp.referralCode" })}
                                                    name='referralCode'
                                                    placeholder={intl.formatMessage({ id: "driver.formSignUp.referralCode" })}
                                                    touched={formik.touched}
                                                    errors={formik.errors}
                                                />
                                                <FormGroup>
                                                    <ReCAPTCHA
                                                        ref={reCaptcha}
                                                        key={keyRecapcha}
                                                        hl={Context.language}
                                                        sitekey={RECAPTCHA}
                                                        onChange={token => setToken(token)}
                                                        onExpired={e => setToken("")}
                                                    />
                                                </FormGroup>

                                                <FormikControl
                                                    control='policy'
                                                    label='Policy'
                                                    name='policy'
                                                    linkTerm={getLinkterm}
                                                    linkPrivacy={getLinkPrivacyPolicy}
                                                    fleetName={fleetInfo.name}
                                                />

                                                <button
                                                    type='submit'
                                                    className="register-btn button"
                                                    disabled={!formik.isValid || token === ""}
                                                    style={(!formik.isValid || token === "") ?
                                                        null
                                                        : { "backgroundColor": `${mainColor}` }
                                                    }
                                                >
                                                    {loading ? (<Spinner animation="border" />) : (<span>{intl.formatMessage({ id: "driver.formSignUp.signUp" })}</span>)}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }
                        }

                    </Formik>
                    :
                    (
                        <ThankYou
                            fleetInfo={fleetInfo}
                            mainColor={mainColor}
                            resData={resData}
                            fleetName={fleetInfo.name}
                            usePassword={usePassword}
                        />
                    )
            )
    )
};

export default injectIntl(SignUpForm);