import React from 'react';
import './thankYou.scss';
import qrCode from '../../../../static/images//qrcode.png';
import link from '../../../../config/link';
import BtnAndroid from '../../../components/HImage/BtnDowloadAndroid';
import BtnIos from '../../../components/HImage/BtnDownloadIOS';
import BtnHuawei from '../../../components/HImage/BtnDownloadHuawei';
import { injectIntl } from "gatsby-plugin-intl"

function ThankYou(props) {
    const { mainColor, fleetName, usePassword, fleetInfo, countryCode } = props;

    return (
        <div className="completed-form">
            <div className="box">
                <div className="box-form">
                    {usePassword
                        ? <div>
                            <p
                                style={{ "color": `${mainColor}` }}
                                className="fleetname-content"
                            >
                                {props.intl.formatMessage({ id: "driver.formSignUp.FINISH_USE_PASSWORD_1" }, { name: fleetName })}
                            </p>
                            <p className="dowload_title">
                                {props.intl.formatMessage({ id: "driver.formSignUp.FINISH_USE_PASSWORD_2" })}
                            </p>
                            <p className="dowload_title">
                                {props.intl.formatMessage({ id: "driver.formSignUp.FINISH_USE_PASSWORD_3" })}
                            </p>
                            <p className="dowload_title">
                                {props.intl.formatMessage({ id: "driver.formSignUp.FINISH_USE_PASSWORD_4" }, { password: <span style={{ fontWeight: 'bold' }}>12345678</span> })}
                            </p>
                            <p className="dowload_title">
                                {props.intl.formatMessage({ id: "driver.formSignUp.FINISH_USE_PASSWORD_5" })}
                            </p>
                        </div>
                        : <div>
                            <p
                                style={{ "color": `${mainColor}` }}
                                className="fleetname-content"
                            >
                                {props.intl.formatMessage({ id: "driver.formSignUp.thank" }, { name: fleetName })}
                            </p>
                            <p className="dowload_title">
                                {props.intl.formatMessage({ id: "driver.formSignUp.downloadApp" }, { name: <span style={{ color: mainColor }}>{fleetName}</span> })}
                            </p>
                        </div>}

                    <div className="qrcode_container">
                        <div className="qr_content">
                            <div className="qrcode">
                                <div>
                                    <img className="img_qrcode" src={qrCode} />
                                </div>
                            </div>
                            <div className="dowload_calculator">
                                <div className="bt_store">
                                    <a href={countryCode !== 'jo' ? link.driverGooglePlay : link.driverAppStoreJordan} className="linkApp" target="_blank">
                                        <BtnAndroid intl={props.intl} />
                                    </a>
                                    <a href={countryCode !== 'jo' ? link.driverAppStore : link.driverAppStoreJordan} className="linkApp" target="_blank">
                                        <BtnIos intl={props.intl} />
                                    </a>
                                    {
                                        fleetInfo?.socialSetting?.appGalleryDrv &&
                                        <a href={fleetInfo?.socialSetting?.appGalleryDrv} className="linkApp" target="_blank">
                                            <BtnHuawei intl={props.intl} />
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default injectIntl(ThankYou)