import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';
import TextError from "./TextError";

function InputField(props) {
    const { label, name, touched, errors, formik, capitalLetter, ...rest } = props;
    const handleInputName = (e) => {
        let value = e.target.value;
        if (capitalLetter && (name === 'firstName' || name === 'lastName')) {
            value = value.toUpperCase();
        }
        formik.setFieldValue(name, value);
    };

    return (
        <Form.Group>
            <Field
                id={name}
                name={name}
                {...rest}
                className={touched[name] && errors[name] ? "error" : null}
                onChange={(e) => handleInputName(e)}
            />
            <ErrorMessage name={name} component={TextError} />
        </Form.Group>
    )
}

export default InputField