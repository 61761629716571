import React from 'react';
import { Field } from 'formik';
import { Form } from 'react-bootstrap';
import { injectIntl } from "gatsby-plugin-intl"
// import { I18n } from "react-redux-i18n";

function TermForm(props) {
    const { label, name, linkTerm, linkPrivacy, fleetName, ...rest } = props;

    return (
        <Form.Group>
            <label className={name}>
                {props.intl.formatMessage({id: "driver.formSignUp.agree"})}{' '}
                <a
                    href={linkTerm}
                    style={{ display: "inline-block" }}
                    className="term-of-service"
                    target="_blank"
                >{props.intl.formatMessage({id: "driver.formSignUp.terms"})}</a>{' '}{props.intl.formatMessage({id: "driver.formSignUp.and"})}{' '}
                <a
                    href={linkPrivacy}
                    className="term-of-service"
                    style={{ display: "inline-block" }}
                    target="_blank"
                >{props.intl.formatMessage({id: "driver.formSignUp.privacy"})}</a>

                {/* {I18n.t("home.I_AGREE_TO", { name: fleetName })}{' '}
                <a
                    href={linkTerm}
                    className="term-of-service"
                    style={{ display: "inline-block" }}
                >
                    {I18n.t("home.TERMS_OF_USE")}
                </a>
                {' '}{I18n.t("home.AND")}{' '}
                <a
                    href={linkPrivacy}
                    className="term-of-service"
                    style={{ display: "inline-block" }}
                >
                    {I18n.t("home.PRIVACY_POLICY")}
                </a>
                {' '}{I18n.t("home.I_AGREE_TO_AFTER")}{' '} */}
            </label>
        </Form.Group>
    )
}

export default injectIntl(TermForm)